<template lang="pug" >
  .panel(:id="ident" v-bind:class="shadowPanel && layoutState == 'UNIT' ? 'shadowPanel' : ''")
  
    .head-sizable-block(v-if="ident && vertical" @mousedown="verticalSizable($event)")
      .horizontal-icon-pos
        v-img(:src="`/img/project/tools/horizontal-sizable.svg`")
  
    .sizable-block(v-if="ident && right && !hiddenControls" @mousedown="horizontalSizable($event)")
      v-img.icon-pos(v-if="!editActive || layoutState != 'UNIT'" :src="`/img/project/tools/sizable.svg`")
      v-img.icon-pos(v-if="!editActive && layoutState == 'DOUBLE'" :src="`/img/project/tools/sizable.svg`")
      v-img.magnet-effect(v-if="editActive && layoutState == 'UNIT'" :src="`/img/project/tools/magnet-effect.svg`")
  
    .sizable-block-right(v-if="ident && left && !hiddenControls" @mousedown="horizontalSizable($event)" v-bind:class="shadowPanel ? 'shadowPanel' : ''")
      v-img.icon-pos(v-if="!editActive || layoutState != 'UNIT'" :src="`/img/project/tools/sizable.svg`")
      v-img.icon-pos(v-if="!editActive && layoutState == 'DOUBLE'" :src="`/img/project/tools/sizable.svg`")
      v-img.magnet-effect(v-if="editActive && layoutState == 'UNIT'" :src="`/img/project/tools/magnet-effect.svg`")
      
    div.panel--huge(:class="[ {'panel--flex' : flex}]" :style="{ padding: !hiddenControls && right ? '5px 5px 5px 5px' : (!hiddenControls && left ? '5px 5px 5px 5px' : (hiddenControls ? '5px':'0px' )) }")
      slot
  </template>
  
  <script>
  import { PanelController } from '@/pages/panelController'
  
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  
  import { panels } from '../../store/sections/LayoutRepository'
  import { throttle } from 'lodash'
  
  export default {
    props: {
      width: [String, Number],
      height: [String, Number],
      ident: null,
      left: Boolean,
      right: Boolean,
      shadowPanel: Boolean,
      flex: Boolean,
      padding: { type: String, default: "5px" }
    },
  
    data () {
      return {
        hiddenControls: false,
      }
    },
  
    computed: {
      ...mapGetters('layoutControler', ['panel', 'panelDataById', 'panelHeight', 'panelWidth']),
      ...mapState('layoutControler', ['editActive', 'layoutState', 'layersLinks']),
  
      startSize() {
        let width = this.width ? `${this.width}px` : '100%'
        let height = this.height ? `${this.height}px` : '100%'
        return `width: ${width}; height: ${height};`
      },
  
      panel() {
        return panels.find(panel => panel.ident == this.ident)
      },
  
      vertical() {
        return this.panel?.vertical
      }
    },
  
    mounted() {
      if (this.ident) {
        let panelData = {
          id: this.ident,
          width: this.panel.minWidth,
          height: this.panel.minHeight,
          exist: true,
          minWidth: this.panel.minWidth,
          minHeight: this.panel.minHeight,
          orientation: this.panel.orientation, 
          hideControls: this.panel.sizable ? true : false
        }      
        this.hiddenControls = !this.panel.sizable
        this.addPanel(panelData)
      }
  
      else this.$el.setAttribute('style', this.startSize)
  
      if (this.panel && !this.panel.sizable && !this.panel.vertical) {
        this.setWidth({id: this.ident, width: window.innerWidth / 100 * this.panel.minWidth })
      }
  
      setTimeout(() => {
        PanelController.setPanelPadding(true)
      })
    },
  
    beforeDestroy() {
      if(this.ident) this.removePanel(this.ident) 
      PanelController.setPanelPadding(true)
    },
  
    methods: {
      ...mapMutations('layoutControler', ['setStartedMousePosition', 'setWidthData']),
      ...mapActions('layoutControler', ['addPanel', 'changeSize', 'startEdit', 'setWidthHeight', 'setWidth', 'setHeight', 'removePanel']),
      ...mapActions('drawings', ['setVersion']),
  
      horizontalSizable (event) {
        if(event && event.preventDefault)
          event.preventDefault()
        this.startEdit({id: this.ident, position: {x: event.clientX, y:  event.clientY}, minWidth: this.width, axis: 'horizontal'})
        
        document.addEventListener('mousemove', this.mousemovehender = throttle(() => {
          this.$emit('widthchange', this.panelWidth(this.ident))
          }, 50))
        document.addEventListener('mouseup', this.mouseuphender = () => {
          document.removeEventListener('mouseup', this.mouseuphender)
          document.removeEventListener('mousemove', this.mousemovehender  )
  
        })
      },
  
      verticalSizable (event) {
        if(event && event.preventDefault)
          event.preventDefault()
        this.startEdit({id: this.ident, position: {x:  event.clientX, y:  event.clientY}, minWidth: this.width, axis: 'vertical'})
      },
  
      setSize(width, height) {
        if (this.layoutState == 'UNIT') {
          if(height != undefined)
            this.setWidthHeight({id: this.ident, width: width, height: height})
          else
            this.setWidth({id: this.ident, width: width})
        }
  
        setTimeout(() => {
          PanelController.setPanelPadding(true)
        })
      },
  
      hideControls () {
        this.hiddenControls = true;
      },
  
      showControls () {
        this.hiddenControls = false;
      },
  
      setWidthForAnotherPanelInState (id, width) {
        this.setWidthData({id: id, width: width})
      }
  
    },
    
  }
  </script>
  
  <style scoped>
  .panel {
    display: inline-block;
    vertical-align: top;
    overflow: none;
    padding: 5px 0;
    position: relative;
  }
  
  .surface{
    min-width: 256px;
  }
  
  .shadowPanel {
    box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.5);
  }
  
  .head-sizable-block{
    width: 100%;
    height: 7px;
    cursor: row-resize;
    z-index: 998;
  }
  
  .sizable-block-right{
    display: inline-block;
    width: 10px;
    height: 998px;
    cursor: col-resize;
    z-index: 998;
    float: right;
  }
  
  .panel--huge {
    height: 100%;
    overflow: hidden;
  }
  
  .magnet-effect{
    position: relative;
    top: 41%;
  }
  
  .icon-pos {
    display: inline-block;
    position: relative;
    top: 38%;
  }
  
  .horizontal-icon-pos {
    display: table;
    position: relative;
    left: 50%;
  }

  .sizable-block{
    display: inline-block;
    width: 10px;
    height: 998px;
    cursor: col-resize;
    z-index: 998;
    float: left;
  }
  
  .panel {
    background-color: var(--v-surface-base);
    color: var(--v-surface-lighten3);
  }
  
  </style>