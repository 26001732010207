import { api } from '@/api'

import debounce from 'lodash/debounce'
import _lodash from 'lodash'

import i18n from '@/plugins/i18n'

import { defaultHeaders } from '@/pinia/attributeCheckingTable.module.js'

const getDefaultState = () => {
  return {
    tableItems: [],
    groups: [],

    settings: {
      fieldsSettings: [],
      sortSettings: {},
    },

    attrCheckingTableSettings: [],

    selectedType: 0,

    groupingFields: [],
    columns: [],
    sorter: {},

    selectedCollisions: [],
    selectedGroup: [],
    availableModels: [],

    lastCheckedItem: null,

    tableItemsBuffer: [],

    taskCollision: {
      isActive: false,
      taskUuid: null,
    },

    groupsClosed: new Set(),

    rowStart: 0,

    visibleCollisionTable: false,
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    groupingFields: ({ groupingFields }) => [...groupingFields],
    //deprecated now used sortedHeaders
    sortedColumns: ({ columns }) => [...columns].sort((a, b) => a.order - b.order),

    collisionGroups: ({ groups }) => [...groups],
    collisionGroupsIndex: ({ groups }) => [0, ...groups.map((el) => el.index)],

    itemsWithoutHidden: ({ tableItems }) => tableItems.filter((el) => !el.hidden),

    selectedCollisions: ({ selectedCollisions }) => [...selectedCollisions],

    selectedCollisionsUuid: ({ selectedCollisions }) => selectedCollisions.map((el) => el.uuid),

    countCollision: ({ tableItems }) => {
      let count = 0
      tableItems.forEach((item) => {
        if (!item.groupTitle) count++
      })
      return count
    },

    getCollision: (state, getters) => (collisionUuid) => {
      if (getters.getPreperedCollision.length > 0) {
        let collision = getters.getPreperedCollision.find((item) => item.uuid === collisionUuid)
        if (collision && collision !== undefined) return collision
      }
      return null
    },

    getPreperedCollision: (state, getters, rootState, rootGetters) => {
      let preparedCollisions = rootGetters['collision/search/preparedCollisions']
      let preparedCollisionsTask = rootGetters['collision/search/preparedCollisionsTask']

      let collisions = [...preparedCollisions, ...preparedCollisionsTask]
      return _lodash.uniq(collisions)
    },

    selectedCollisionsElementAB: ({ selectedCollisions }, getters) => {
      let list = []
      selectedCollisions.forEach((selectedCollsion) => {
        if (getters.getPreperedCollision.length > 0) {
          let collision = getters.getPreperedCollision.find((item) => item.uuid === selectedCollsion.uuid)
          if (collision && collision !== undefined) list = [...list, collision.globalIdA, collision.globalIdB]
        }
      })
      return list
    },

    selectedCollisionsModelAB: ({ selectedCollisions }, getters) => {
      let list = []
      selectedCollisions.forEach((selectedCollsion) => {
        if (getters.getPreperedCollision.length > 0) {
          let collision = getters.getPreperedCollision.find((item) => item.uuid === selectedCollsion.uuid)
          if (collision && collision !== undefined) list = [...list, collision.info.modelA, collision.info.modelB]
        }
      })
      return list
    },

    headers: ({ settings }) => settings.fieldsSettings,
    sortedHeaders: ({ settings }) => [...settings.fieldsSettings].sort((a, b) => a.order - b.order),
    visibleHeaders: ({ settings, attrCheckingTableSettings }) =>{
      const headersValues = defaultHeaders()?.map(item => item.value) || [];
      for (let i = settings.fieldsSettings.length - 1; i >= 0; i--) {
        if (headersValues.includes(settings.fieldsSettings[i].value) && !attrCheckingTableSettings.find(header => header.value === settings.fieldsSettings[i].value)) {
          attrCheckingTableSettings.push(settings.fieldsSettings[i])
          settings.fieldsSettings.splice(i, 1)
        }
      }

      return settings.fieldsSettings.filter((o) => o.view).sort((a, b) => a.order - b.order
    )},

    attrTableVisibleHeaders: ({ attrCheckingTableSettings }) => attrCheckingTableSettings.filter((o) => o.view).sort((a, b) => a.order - b.order),

    availableModels: ({ availableModels }) => availableModels,

    getVisibleCollisionTable: ({ visibleCollisionTable }) => visibleCollisionTable,
  },

  mutations: {
    resetState(state){
      const fieldsSettings = state.settings.fieldsSettings
      Object.assign(state, getDefaultState())
      state.settings.fieldsSettings = fieldsSettings 
    },

    SET_TABLE_ITEMS: (state, list) => {
      state.tableItems = list.map((el, index) => {
        el.index = index
        el.selected = false
        el.hidden = el.hidden ? true : false
        return el
      })
    },

    SET_TABLE_ITEMS_FOR_REPLACE: (state, list) => {
      state.tableItems = list
    },

    SET_CLOSED_GROUP: (state, group) => {
      group.isOpen ? state.groupsClosed.add(group.groupUuid) : state.groupsClosed.delete(group.groupUuid)
    },

    SET_SELECTED_TYPE: (state, type) => {
      state.selectedType = type
    },

    SET_GROUPS: (state, groups) => {
      state.groups = groups
    },

    SET_SORT: (state, options) => {
      //TODO: ПЕРЕДЕЛАТЬ
      state.sorter = { ...options }
    },
    SET_GROUPING_FIELDS: (state, fields) => {
      state.groupingFields = [...fields]
    },
    UPDATE_HEADER: (state, header) => {
      state.settings.fieldsSettings = state.settings.fieldsSettings.map((el) => (el.value !== header.value ? el : { ...el, ...header }))
    },
    SELECT_HEADERS: (state, fields) => {
      state.settings.fieldsSettings = state.settings.fieldsSettings.map((el) => ({ ...el, view: fields.includes(el.value) }))
    },
    SET_COLUMNS: (state, columns) => {
      state.settings.fieldsSettings = [...columns]
    },
    SET_SELECTED_COLLISIONS: (state, list) => {
      state.selectedCollisions = [...list]
    },

    SET_AVAILABLE_MODELS: (state, list) => {
      state.availableModels = [...list]
    },

    SET_LAST_CHECKED_ITEM: (state, item) => {
      state.lastCheckedItem = item
    },

    SET_SELECTED_GROUP: (state, selectedGroup) => {
      state.selectedGroup = selectedGroup
    },

    UPDATE_TABLE_ITEMS: (state, options) => {
      for (let collision of options.collisions) {
        let newCollision = options.data.find((newCol) => newCol.uuid == collision.uuid)
        state.tableItems.splice(collision.index, 1, Object.assign({}, collision, collisionMapper(newCollision)))
      }
    },

    SET_HIDDEN_ITEMS(state, group) {
      if (group?.groupTitle) {
        let items = state.tableItems
        group.isOpen = !group.isOpen
        items.splice(group.index, 1, group)

        for (let i = group.index + 1; i < items.length; i++) {
          if (!items[i]?.groupTitle) {
            items[i].hidden = !items[i].hidden
          } else {
            break
          }
        }
      }
    },

    TOGGLE_ALL_GROUP(state, isOpen) {
      if (isOpen) state.groupsClosed.clear()
      let hidden = !isOpen
      let items = state.tableItems
      for (let item of items) {
        if (item.groupTitle) {
          item.isOpen = isOpen
          if (!isOpen) state.groupsClosed.add(item.groupUuid)
        } else {
          item.hidden = hidden
        }
      }
    },

    SET_SETTINGS: (state, settings) => {
      state.settings = settings || {}
      state.groupingFields = settings.groupFields
      state.columns = settings.fieldsSettings
      state.sorter = { ...settings.sortSettings }
    },

    SET_TABLE_ITEMS_BUFFER: (state, list) => {
      state.tableItemsBuffer = list
    },

    CHANGE_COLLISION_SOURCE: (state, source) => {
      if (source.isActive) {
        if (state.taskCollision.isActive != source.isActive) {
          state.tableItemsBuffer = state.tableItems.map((el, index) => {
            el.index = index
            el.selected = false
            el.hidden = el.hidden ? true : false
            return el
          })
        }

        state.tableItems = source.list
      } else {
        state.tableItems = state.tableItemsBuffer
        state.tableItemsBuffer = []
      }

      state.taskCollision.isActive = source.isActive
      if (source.id) state.taskCollision.taskUuid = source.id

      state.rowStart = 0
    },

    SET_ROW_START: (state, num) => {
      state.rowStart = num
    },

    SET_COLLISION_TABLE_VISIBILITY: (state, boolean) => {
      state.visibleCollisionTable = boolean
    }
  },

  actions: {
    refreshSettings({commit }) {
      // if (state.settings.fieldsSettings.length === 0) { // (???) CHECK LATER
        return api.collisionSettings.getTableSettings().then((settings) => {
          commit('SET_SETTINGS', settings)
        })
      // }
    },

    resetColumnWidth({ commit }) {
      api.collisionSettings.resetColumnWidth().then((settings) => {
        commit('SET_SETTINGS', settings)
      })
    },

    initTableItems({ state, commit, dispatch, rootGetters }, tableGroups) {
      let tableItems = []
      let groups = []
      let index = 0

      if (tableGroups.length > 1 || tableGroups[0]?.collisions) {
        tableGroups.forEach((group) => {
          let { groupUuid, groupTitle, collisions } = group

          if (!groupUuid && !groupTitle) {
            groupTitle = 'Неотсортированные'
          } else {
            groups.push({ groupUuid, groupTitle, index })
          }

          tableItems.push({ groupUuid, groupTitle, isOpen: true }, ...collisions)
          index += collisions.length + 1
        })

        for (let i = 0; i < tableItems.length; i++) {
          if (tableItems[i].groupTitle && state.groupsClosed.has(tableItems[i].groupUuid)) {
            tableItems[i].isOpen = false
            tableItems[i].index = i
            tableItems[i].selected = false
            tableItems[i].hidden = false

            for (i = i + 1; i < tableItems.length; i++) {
              if (!tableItems[i]?.groupTitle) {
                tableItems[i].hidden = true
                tableItems[i].index = i
                tableItems[i].selected = false
              } else {
                i--
                break
              }
            }
          } else {
            tableItems[i].index = i
            tableItems[i].selected = false
            tableItems[i].hidden = false
          }
        }

        // tableItems.forEach((el, index) => {
        //   el.index = index
        //   el.selected = false
        //   el.hidden = false
        // })

        if (state.taskCollision.isActive) {
          commit('SET_TABLE_ITEMS_BUFFER', tableItems)
          commit('SET_TABLE_ITEMS', rootGetters['collision/search/preparedCollisionsTask'])
        } else {
          commit('SET_SELECTED_GROUP', [])
          commit('SET_SELECTED_COLLISIONS', [])
          commit('SET_TABLE_ITEMS', tableItems)
          dispatch('updateCollisionsList')
        }
        commit('SET_GROUPS', groups)
      }
    },

    setColumnWidth({ commit }, header) {
      commit('UPDATE_HEADER', { field: header.value, width: header.width })
      debounced_sendUpdatedColumn(header)
    },

    selectColumns({ commit }, fields) {
      commit('SELECT_HEADERS', fields)
      debounced_sendSelectedColumns(fields)
    },

    reorderColumns({ commit }, columns) {
      columns.forEach((el, i) => {
        el.order = i
      })
      commit('SET_COLUMNS', columns)
      debounced_sendReorderedColumns(columns)
    },

    setGroupingFields({ commit, dispatch }, fields) {
      api.collisionSettings.setGroupingFields(fields).then(() => {
        commit('SET_GROUPING_FIELDS', fields)
        dispatch('collision/search/loadCollisions', null, { root: true })
      })
    },

    selectCollisions({ commit }, list) {
      commit('SET_SELECTED_COLLISIONS', list)
    },

    setTableSort({ state, commit, dispatch, rootState }, options) {
      if (state.sorter.sort == options.sort && state.sorter.value == options.value) return 'SAME'
      return api.collisionSettings.setTableSorter(options.value, options.sort).then(() => {
        commit('SET_SORT', options)
        if (rootState.collision.search.filter.axisNodes) {
          return dispatch('collision/search/loadCollisions', null, { root: true })
        } else return true
      })
    },

    updateColumns(obj, list) {
      api.collisionSettings.updateColumns(list)
    },

    reassignTo({ dispatch }, { model, uuids }) {
      api.collisions.reassignTo(model, uuids).then((data) => {
        // dispatch('collision/search/loadCollisions', null, { root: true })
        dispatch('replaceCollisions', data)
        // commit('SET_SELECTED_COLLISIONS', [])
      })
    },

    changeIsCollisionValue(obj, collisionUuid) {
      api.collisions.changeIsCollisionValue(collisionUuid)
    },

    getAvailableModels({ commit }, uuids) {
      commit('SET_AVAILABLE_MODELS', [])
      api.collisions.getAvailableModels(uuids).then((data) => {
        commit('SET_AVAILABLE_MODELS', data)
      })
    },

    loadTaskCollisions({ commit, dispatch, rootGetters }, task) {
      commit('SET_SELECTED_COLLISIONS', [])
      commit('SET_SELECTED_GROUP', [])
      let collisionsUuid = task.collision.map((el) => el.uuid)
      return api.collisions.getCollisionsList(collisionsUuid).then((collisions) => {
        commit('collision/search/SET_COLLISIONS_TASK', collisions, { root: true })
        commit('CHANGE_COLLISION_SOURCE', { isActive: true, id: task.uuid, list: rootGetters['collision/search/preparedCollisionsTask'] })
        dispatch('replaceCollisions', collisions)
      })
    },

    updateCollisionsList({ state, commit, getters }) {
      let start = state.rowStart
      let limit = start + 30
      let end, collisions
      let list = getters.itemsWithoutHidden
      end = limit > list.length ? list.length : limit
      collisions = list.slice(start, end).filter((el) => !(el.displayedModelA || el.displayedModelA === '' || el.groupTitle)) //TODO: может упасть

      if (collisions.length > 0) {
        let collisionsUuid = collisions.map((el) => el.uuid)
        return api.collisions.getCollisionsList(collisionsUuid).then((data) => {
          commit('UPDATE_TABLE_ITEMS', { collisions, data })
          return data
        })
      }
    },

    deleteGroup({ dispatch }, uuid) {
      api.collisionGroups.delete(uuid).then(() => {
        dispatch('collision/search/loadCollisions', null, { root: true })
      })
    },

    editGroup({ dispatch }, { title, uuid }) {
      api.collisionGroups.edit(uuid, title).then(() => {
        dispatch('collision/search/loadCollisions', null, { root: true })
      })
    },

    allowGroup({ dispatch }, { uuid, note }) {
      api.collisionGroups.setAllowed(uuid, note).then(() => {
        dispatch('collision/search/loadCollisions', null, { root: true })
      })
    },

    createGroup({ dispatch, rootGetters, getters }, { title, uuids }) {
      let projectUUID = rootGetters['project/projectUuid']
      let camera = JSON.stringify(rootGetters['project/camera']())
      if (!uuids) uuids = getters.selectedCollisionsUuid
      api.collisionGroups.createWithCollisions(projectUUID, title, uuids, camera).then(() => {
        dispatch('collision/search/loadCollisions', null, { root: true })
      })
    },

    addCollisionToGroup({ dispatch }, { groupUuid, collisionList }) {
      api.collisionGroups.addCollisionsToGroup(groupUuid, collisionList).then(() => {
        dispatch('collision/search/loadCollisions', null, { root: true })
      })
    },

    toggleAllGroup({ commit, dispatch }, isOpen) {
      commit('TOGGLE_ALL_GROUP', isOpen)
      if (isOpen) dispatch('updateCollisionsList')
    },

    setGroupCamera(obj, { uuid, camera }) {
      api.collisionGroups.setGroupCamera(uuid, camera)
    },

    replaceCollisions({ state, rootState, commit }, data) {
      let items = [...state.tableItems]

      let collisions = Array.isArray(data) ? data : [data]
      let taskCollision = [...rootState.collision.search.collisionsTask]

      collisions.forEach((col) => {
        for (let i in items) {
          if (items[i].uuid === col.uuid) {
            if ((items[i].elementA && items[i].elementA.uuid != null) || (items[i].elementB && items[i].elementB.uuid != null)) {
              col.index = items[i].index
              col.selected = items[i].selected
              col.hidden = items[i].hidden

              items[i] = collisionMapper(col)
            }
            break
          }
        }

        if (state.taskCollision.isActive) {
          let buffer = [...state.tableItemsBuffer]
          for (let i in buffer) {
            if (buffer[i].uuid === col.uuid) {
              if ((buffer[i].elementA && buffer[i].elementA.uuid != null) || (buffer[i].elementB && buffer[i].elementB.uuid != null)) {
                buffer[i] = collisionMapper(col)
              }
              break
            }
          }
          state.tableItemsBuffer = buffer
        }

        if (taskCollision && taskCollision.length > 0) {
          for (let i in taskCollision) {
            if (taskCollision[i].uuid === col.uuid) {
              taskCollision[i] = collisionMapper(col)
              break
            }
          }
        }
      })

      commit('SET_TABLE_ITEMS_FOR_REPLACE', items)
      commit('collision/search/SET_COLLISIONS_TASK', taskCollision, { root: true })
      commit('collision/search/SET_COLLISIONS_FOR_REPLACE', items, { root: true })
    },

    updateCollisionTaskList({ state }, { collisionId, taskId }) {
      if (
        (!state.taskCollision.isActive && state.tableItems && state.tableItems.length > 0) ||
        (state.tableItemsBuffer && state.tableItemsBuffer.length > 0)
      ) {
        if (state.taskCollision.isActive) {
          let index = state.tableItemsBuffer.findIndex((el) => el.uuid === collisionId)
          let taskIndex = state.tableItemsBuffer[index].tasks.findIndex((task) => task.uuid === taskId)
          state.tableItemsBuffer[index].tasks = state.tableItemsBuffer[index].tasks.splice(taskIndex, 1)
        } else {
          let index = state.tableItems.findIndex((el) => el.uuid === collisionId)
          let taskIndex = state.tableItems[index].tasks.findIndex((task) => task.uuid === taskId)
          state.tableItems[index].tasks = state.tableItems[index].tasks.splice(taskIndex, 1)
        }
      }
    },

    changeActiveDistance({ state }, { collisionUuid, value }) {
      let collision = state.tableItems.find((el) => el.uuid === collisionUuid)
      collision.displayedDistance = value
      api.collisions.changeActiveDistance({ collisionUuid: collisionUuid, value: value })
    },

    setTrueOrFalse({state}, data) {
      state
      api.collisions.setTrueOrFalse(data).then() 
    },
    setCollisionTypes({state}, data) {
      state
      api.collisions.setCollisionTypes(data).then()
    },

    updateAllowedDistances({state}, data) {
      state
      api.collisions.updateAllowedDistances(data).then()
    }
  },
}

function collisionMapper(c) {
  let ext = { ...c }
  let { info, status, elementA, elementB, note, createDate, collisionResponder, groupName, tasks, collisionValue } = c

  ext.displayedStatus = status.title || ''
  ext.displayedNote = note
  ext.displayedDate = createDate
  ext.displayedResponder = collisionResponder?.modelTitle

  ext.displayedFloorA = elementA && elementA.uuid != null ? elementA.floorName : ''
  ext.displayedFloorB = elementB && elementB.uuid != null ? elementB.floorName : ''

  let row = info || {}
  let distanceList = collisionValue && collisionValue.values.length > 0 ? collisionValue.values : []
  distanceList = distanceList.map((el) => (el += ' мм'))

  ext.displayedName = row.finder
  ext.displayedDescription = row.shortDescription != '' ? row.shortDescription : row.description
  ext.tooltipDescription = row.description

  let rowA = row.modelA?.includes('model.name') ? i18n.t('notify.' + row.modelA.replace('{', '').replace('}', '')) : row.modelA
  let rowB = row.modelB?.includes('model.name') ? i18n.t('notify.' + row.modelB.replace('{', '').replace('}', '')) : row.modelB
  ext.displayedSection = elementB && elementB.uuid != null ? `${rowA} - ${rowB}` : `${rowA}`

  ext.displayedModelA = elementA && elementA.uuid != null ? elementA.name : ''
  ext.displayedModelB = elementB && elementB.uuid != null ? elementB.name : ''

  ext.displayedSample = elementB && elementB.uuid != null ? `${row.ruleATitle} / ${row.ruleBTitle}` : `${row.ruleATitle}`

  ext.displayedDistance = row.value

  ext.displayedGroup = groupName
  ext.displayedAxis = '--'
  ext.displayedTask = tasks

  ext.distanceList = distanceList

  return ext
}

const debounced_sendSelectedColumns = debounce((fields) => api.collisionSettings.selectColumns(fields), 1000)
const debounced_sendReorderedColumns = debounce((columns) => api.collisionSettings.updateColumns(columns), 1000)
const debounced_sendUpdatedColumn = debounce((header) => api.collisionSettings.updateTableHeader(header), 500)
